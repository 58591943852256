<script>
export default {
  name: 'information-detail',
  data() {
    return {
      information: null
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.axios.get(`information/slug/${this.$route.params.slug}`)
        .then( response => {
          this.information = response.data.result.information
        })
        .catch( () => {
          this.$router.push('/404')
        })
    },
  }
}
</script>

<template>
  <div v-if="information">
    <div class="head-for-bg mobile-for-head" :style="{ backgroundImage: `url(${$store.getters.backgroundImage})` }">
      <img :src="$store.getters.backgroundImage" class="hide-mobile" alt="" />
      <div class="for-title pl-res-10 pr-res-10 top-30">
        <span>{{ information.title }}</span>
      </div>
    </div>
    <div class="container">
      <div class="_sub-container">
        <div class="breadcump">
          <ul>
            <template v-if="information.category == 'public'">
              <li class="">
                <a class="hide-mobile" href="/public-informaion">Daftar Informasi Publik</a>
                <a class="hide-desktop" href="/public-informaion">Daftar Informasi Publik <i class="fa fa-angle-right"></i> </a>
                <h4 class="hide-desktop">{{ information.title }}</h4>
              </li>
            </template>
            <template v-else-if="information.category == 'important'">
              <li class="">
                <a href="/important-information" class="hide-mobile">Daftar Informasi Penting</a>
                <a class="hide-desktop" href="/important-information">Daftar Informasi Penting <i class="fa fa-angle-right"></i> </a>
                <h4 class="hide-desktop">{{ information.title }}</h4>
              </li>
            </template>
            <template v-else>
              <li class="">
                <a href="/regulation" class="hide-mobile">Daftar Regulasi</a>
                <a class="hide-desktop" href="/regulation">Daftar Regulasi <i class="fa fa-angle-right"></i> </a>
                <h4 class="hide-desktop">{{ information.title }}</h4>
              </li>
            </template>
            <li class="width-70 w-res-50 hide-mobile">
              <h4 class=" ">{{ information.title }}</h4> </li>
          </ul>
        </div>
        <div class="content-detail-layanan">
          <img v-if="information.banner" :src="information.banner" alt="">
          <p class="ln-2 mt-2" v-html="information.content"></p>
        </div>
      </div>
    </div>
  </div>
</template>